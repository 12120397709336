import React from 'react';

import { Riff } from './riff';

export const Tape = () => {
    return (
        <div>
            <Riff />
        </div>

    )
}