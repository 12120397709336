import 'bootstrap/dist/css/bootstrap.min.css';

import './scss/app.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import { Tape } from './components/tape';
import { BigLogo } from './components/biglogo';
import { Features } from './components/features';

const App = () => {
    return (
        <div className="intropage">
            <BigLogo />
        </div>
    )
}

ReactDOM.render(
    <App />,
    document.getElementById('app')
);