import React from 'react';

import riffLogo from'../img/rifftape_white.svg';

export const BigLogo = () => {
    return (
        <section className="biglogo">
            <div className="biglogo__content">
                <img src={riffLogo}  alt="rifftape logo"/>
            </div>
        </section>
    )
}