import React from 'react';

export const Features = () => {
    return (
        <section className="features">
            <div className="container">
                <p>this is features</p>
            </div>
        </section>
    )
}